import { graphql, useStaticQuery } from 'gatsby';

function useHeroImage() {
  const { contentfulImages, title, subTitle } = useStaticQuery(query);
  return {
    image: contentfulImages.image,
    title: title.text.text,
    subTitle: subTitle.text.text,
  };
}

const query = graphql`
  query HomepageHeroQuery {
    contentfulImages(identifier: { eq: "homepage-hero" }) {
      id
      image {
        title
        fluid(maxWidth: 1440) {
          ...GatsbyContentfulFluid
        }
      }
    }
    title: contentfulTextFields(identifier: { eq: "homepage-title" }) {
      text {
        text
      }
    }

    subTitle: contentfulTextFields(identifier: { eq: "homepage-subtitle" }) {
      id
      text {
        text
      }
    }
  }
`;

export default useHeroImage;
