import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import GatsbyImage from 'gatsby-image';
import { Link as GatsbyLink } from 'gatsby';

const StyledProject = styled(GatsbyLink)`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  text-decoration: none;
`;

const Description = styled.p`
  color: ${({ theme }) => theme.colors.black};
  margin-top: 0.2rem;
  text-align: center;
  text-decoration: none;
  ${({ theme }) => theme.sizes.smallP};
`;

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.playfair};
  text-align: center;
  margin-top: 0.8rem;
  text-decoration: none;
  ${({ theme }) => theme.sizes.f3};
`;

const Image = styled(GatsbyImage)`
  height: 300px;
`;

function Project({ date, description, image, imageHeight, slug, title }) {
  return (
    <StyledProject to={`/furniture/${slug}`}>
      <Image alt={image.title} fluid={image.fluid} />
      <Title>{title}</Title>
      <Description>{description.description}</Description>
    </StyledProject>
  );
}

Project.propTypes = {
  date: PropTypes.string,
  description: PropTypes.shape({
    description: PropTypes.string,
  }).isRequired,
  image: PropTypes.shape({
    fluid: PropTypes.any.isRequired,
  }).isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

Project.defaultProps = {};

export default Project;
