import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

import BackgroundImage from 'gatsby-background-image';
import Nav from '../Nav';

import useHeroData from './useHeroData';

const StyledBackgroundImage = styled(BackgroundImage)`
  min-height: 100vh;
`;

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;

const StyledNav = styled(Nav)`
  position: relative;
  z-index: 2;
`;

// const TitleWrapper = styled.div`
//   align-items: center;
//   box-sizing: border-box;
//   display: flex;
//   flex: 1;
//   justify-content: center;
//   position: relative;
//   z-index: 2;
// `;

const StyledContainer = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vwh;
`;

const NavWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

const SubTitle = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.serif};
  margin-top: 6px;
  font-weight: 300;
  z-index: 2;
  ${({ theme }) => theme.sizes.p};
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.playfair};
  font-weight: 500;
  max-width: 80vw;
  position: relative;
  z-index: 2;
  ${({ theme }) => theme.sizes.f1};
`;

function Hero({ location }) {
  const { image, subTitle, title } = useHeroData();
  return (
    <StyledBackgroundImage tag="main" fluid={image.fluid}>
      <Content>
        <NavWrapper>
          <StyledNav location={location} />
        </NavWrapper>
        <StyledContainer>
          <Title style={{ textAlign: 'center' }}>{title}</Title>
          <SubTitle style={{ textAlign: 'center' }}>{subTitle}</SubTitle>
        </StyledContainer>
      </Content>
      <Overlay />
    </StyledBackgroundImage>
  );
}

Hero.propTypes = {};

Hero.defaultProps = {};

export default Hero;
