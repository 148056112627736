import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

import Container from 'components/Container';
import Project from './Project';

import useFurniture from './useFurniture';
import { media } from 'styles/theme';

const Grid = styled.div`
  display: grid;
  grid-gap: 22px;
  grid-template-columns: 1fr;
  ${media.md} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${media.lg} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const Title = styled.h2`
  font-color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.playfair};
  margin-bottom: 60px;
  ${({ theme }) => theme.sizes.f2};
`;

function LatestProjects() {
  const projects = useFurniture().slice(0, 3);
  return (
    <Container>
      <Title>Latest Projects</Title>
      <Grid>
        {projects.map(project => (
          <Project key={project.id} {...project} />
        ))}
      </Grid>
    </Container>
  );
}

LatestProjects.propTypes = {};

LatestProjects.defaultProps = {};

export default LatestProjects;
