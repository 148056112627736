import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import LatestProjects from 'components/LatestProjects';
import Hero from 'components/Hero';
import SEO from 'components/SEO';

function IndexPage({ data, location }) {
  const { pageTitle, pageDescription, seoImage } = data.contentfulPageSeo;
  const seo = {
    description: pageDescription,
    imageUrl: seoImage.file.url,
    title: pageTitle,
  };
  return (
    <Layout>
      <SEO {...seo} />
      <Hero location={location} />
      <LatestProjects />
    </Layout>
  );
}

export const query = graphql`
  query HomePageQuery {
    contentfulPageSeo(identifier: { eq: "homepage" }) {
      pageTitle
      pageDescription
      seoImage {
        file {
          url
        }
      }
    }
  }
`;

export default IndexPage;
